<template>
  <div>
    <week-table-skeleton v-if="isLoading" />
    <div
      v-else
      id="scrollable-container"
      class="bg-white border rounded my-1  scrollable-container"
    >
      <div
        class="rounded  d-flex position-relative"
        style="width: 130%"
      >
        <div
          style="width: 16.6%"
          class="border-right d-flex flex-column position-sticky position-top-0 position-left-0 bg-white"
        >
          <span
            style="width: 100%; height: 55px"
            class="border-bottom d-flex align-items-center justify-content-center"
          >
            <h1
              style="color: #646464"
              class="font-weight-bolder font-small-4 m-0"
            >
              {{ 'Unresolved' }}
            </h1>
          </span>
          <span
            style="height: 15px"
            class="border-bottom"
          />
          <span
            style="height: 290px"
            class="d-flex flex-column align-items-center justify-content-start pt-1"
          >
            <span
              v-for="order in weekOrder.unresolved"
              :key="order.id"
              :style="`height: 30px; width: 180px; margin-bottom: 8px; background-color: ${orderStatesAndStatuses[order.status] && orderStatesAndStatuses[order.status].style.color}`"
              class="d-flex align-items-center cursor-pointer"
            >
              <feather-icon
                icon="LTruckIcon"
                size="16"
                style="margin: 8px"
                class="text-white stroke-current"
              />
              <p
                style="margin-top: 15px"
                class="text-white"
              >{{ order._order_id }}</p>
              <p
                style="margin-top: 15px; margin-left: 3px"
                class="text-white"
              >{{ order.transfer_time }}</p>
            </span>
          </span>
        </div>
        <div
          v-for="day in days(warehouseDatePicker.selectDayOfWeek)"
          id="warehouseDatePicker"
          :key="day"
          :style="`width: 16.8%; background-color: ${day === getToday ? '#00B13926' : ''}`"
          class="border-right d-flex flex-column warehouseDatePicker"
        >
          <span
            style="width: 100%; height: 55px"
            class="border-bottom d-flex align-items-center justify-content-center"
          >
            <h1
              style="color: #646464"
              class="font-weight-bolder font-small-4 m-0"
            >{{ day }}</h1>
          </span>
          <span
            style="height: 15px"
            class="border-bottom"
          />
          <span
            style="height: 290px"
            class="d-flex flex-column align-items-center justify-content-start pt-1 scrollable-span"
          >
            <span
              v-for="order in weekOrder.orders[day]"
              :key="order.order_id"
              :style="`height: 30px; width: 180px; margin-bottom: 8px; background-color: ${ order.status ? orderStatesAndStatuses[order.status] && orderStatesAndStatuses[order.status].style.color : (order.order_status ? orderStatesAndStatuses[order.status] && orderStatesAndStatuses[order.order_status].style.color : '' ) }; display: ${order.status ? getFilteredItems(order.status) : (order.order_status ? getFilteredItems(order.order_status) : '')} !important`"
              class="bg-dropbox d-flex align-items-center cursor-pointer"
              @click="handleOrderClick(order)"
            >
              <feather-icon
                icon="LWarehouseIcon"
                size="16"
                style="margin: 8px"
                class="text-white stroke-current"
              />
              <p
                style="margin-top: 15px"
                class="text-white"
              >{{ order._order_id }}</p>
              <p
                style="margin-top: 15px; margin-left: 3px"
                class="text-white"
              >{{ order.transfer_time ? order.transfer_time : '' }}</p>
            </span>
          </span>
        </div>
      </div>
      <portal to="body-footer">
        <div>
          <manage-weekly-date />
        </div>
      </portal>
    </div>
  </div>
</template>
<script>
import ManageWeeklyDate from '@/views/main/warehouse/main-order/components/use-as-global/ManageWeeklyDate.vue'
import WeekTableSkeleton from '@/views/main/warehouse/main-order/components/skeletons/WeekTableSkeleton.vue'
import config from '../../config'

export default {
  name: 'WeekTable',
  components: { WeekTableSkeleton, ManageWeeklyDate },
  computed: {
    getToday() {
      const day = new Date().toLocaleDateString('en-us', { day: '2-digit' })
      const weekDay = new Date().toLocaleDateString('en-us', { weekday: 'short' })
      return `${weekDay} ${day}`
    },
    getGlobalVariable() {
      return this.$store.state[this.MODULE_NAME].globalVariable
    },
    weekOrder() {
      return this.$store.state[this.MODULE_NAME].warehouseOrdersList
    },
    warehouseDatePicker() {
      return this.$store.state[this.MODULE_NAME].warehouseDatePicker
    },
    isLoading() {
      return this.$store.state[this.MODULE_NAME].isLoading
    },
  },
  watch: {
    isLoading(value) {
      if (!value) {
        this.$nextTick(() => {
          this.scrollToRight()
        })
      }
    },
  },
  methods: {
    scrollToRight() {
      const scrollContainer = document.getElementById('scrollable-container')
      const warehouseDatePicker = document.getElementById('warehouseDatePicker')
      if (!scrollContainer || !warehouseDatePicker) return
      scrollContainer.scrollLeft += warehouseDatePicker.offsetWidth + 1 // 1px for border
    },
    handleOrderClick(order) {
      if (!order.order_type) return

      this.handleOrderStatus(this, order)
    },
    getFilteredItems(status) {
      let display = ''
      if (Object.keys(this.getGlobalVariable).length === 0) {
        return display
      }
      if (!this.getGlobalVariable || !this.getGlobalVariable[status]) {
        return 'none'
      }
      if (this.getGlobalVariable[status] !== true) {
        display = 'none'
      }
      return display
    },
    days(value) {
      const week = []
      // Starting Sunday  not Monday
      const current = new Date(value)
      const first = current.getDate() - current.getDay()
      current.setDate(first)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 7; i++) {
        const weekDay = String(new Date(+current).toLocaleDateString('en-us', {
          weekday: 'short',
          day: '2-digit',
        })).split(' ').reverse().join(' ')
        week.push(weekDay)
        current.setDate(current.getDate() + 1)
      }
      return week
    },
  },
  setup() {
    const {
      orderStatesAndStatuses, checkItemStateAndStatus, MODULE_NAME,
      handleOrderStatus,
    } = config()

    return {
      orderStatesAndStatuses,
      checkItemStateAndStatus,
      MODULE_NAME,
      handleOrderStatus,
    }
  },

}

</script>
<style scoped>
.scrollable-span {
  display: block;
  max-height: 290px;
  overflow-y: auto;
}

.scrollable-container {
  cursor: pointer;
  overflow-x: scroll !important;

  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #636363;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: grey;
  }
}

.warehouseDatePicker{
  &:last-child{
    border-right: none !important;
  }
}
.my-component {
  background-color: lightblue;
}

</style>
